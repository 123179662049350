@import '../../../styles/common/functions';
@import "../../../styles/common/mixins";


.bodyModalOpen {
  overflow: hidden;
}

.modalOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  overflow: auto;
  background-color:rgba(22, 29, 48, 0.7);
}

.modal {
  position: fixed;
  z-index: 2;
  width: 652px;
  top: calc(50% - 613px/2);
  left: calc(50% - 652px/2);
  padding: 48px;
  background: rgba(245, 250, 252, 0.07);
  border-radius: 16px;
  outline: none;
  border: 1px solid rgba(245, 250, 252, 0.2);
  box-shadow: none;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);

  &.safari {
    background: rgba(33, 42, 67, 0.93);
  }

  &.wide {
    max-width: 800px;
  }
  button {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
}

.confirmContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@include media(mobile) {
  .bodyModalOpen {
    overflow: auto;
  }
  .modal {
    width: 349px;
    max-height: 681px;
    left: calc(50% - 349px/2 + 0.5px);
    top: 5%;
    overflow-y: scroll;
  }
}