@import "src/styles/common/mixins";

.slideStyles {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  transition: ease-out;
}

.arrowStyles {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 32px;
  font-size: 32px;
  z-index: 1;
  cursor: pointer;
}

.sliderStyles {
  position: relative;
  height: 100%;
  margin-top: 64px;
}

.dotsContainerStyles {
  display: flex;
  justify-content: center;
}

.dotStyle {
  cursor: pointer;
  margin: 0 5px;
  opacity: 0.25;
  &.dotActive {
    opacity: 1;
  }
}

.slidesContainerStyles {
  display: flex;
  height: 100%;
  transition: transform ease-out 0.3s;
  transform: translateX(-(1*100%)px);
}

.slidesContainerOverflowStyles {
  overflow: hidden;
  height: 100%;
  margin-left: -1px;
}

@include media(mobile) {
  .dotsContainerStyles {
    margin: 0;
    padding: 48px 0;
  }
}