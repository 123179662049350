@import "src/styles/common/mixins";
@import "src/styles/common/functions";

.header {
  position: relative;
  z-index: 2;
}
.nav {
  padding: 22px 0;
}

.navLink {
  position: relative;
  margin: 0 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  opacity: 0.6;
  &.active {
    font-weight: 500;
    opacity: 1;
  }
}

.mobileMenu {
  background: get-color('background', 'accent');
}

.mobileHeader {
  padding: 25px 20px;
  backdrop-filter: blur(24px);
}

.mobileNav {
  margin-top: 169px;
  p {
    text-transform: uppercase;
  }
}
.menuButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 157px;
  gap: 12px;
  button {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: 48px;
    width: 189px;
  }
  .connectWallet {
    color: get-color('text', 'accent');
    background: get-color('background', 'gray');
    border-radius: 4px;
    border-color: get-color('text', 'gray');
    &:hover {
      color: get-color('text', 'gray');
      border-color: get-color('text', 'gray')!important;
    }
  }
  .developerGuide {
    border-color: get-color('background', 'gray');
    &:hover {
      border-color: get-color('text', 'gray')!important;
    }
  }
}

@include media(tablet, mobile) {
  .header {
    padding: 0 20px;
    backdrop-filter: none;
  }
}

@include media(mobile) {
  .header {
    max-height: 54px;
  }
  .nav {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}