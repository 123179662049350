@import 'variables';

@function format-media-query-string($queries) {
  $query: '';

  @each $item in $queries {
    $query: $query + ' and (' + $item + ')';
  }

  @return $query;
}

@mixin mobile($queries...) {
  @media #{'(max-width: #{map-get($breakpoints, 'xm') - 1px})' + format-media-query-string($queries)} {
    @content;
  }
}

@mixin tablet($queries...) {
  @media #{'(min-width: #{map-get($breakpoints, 'xm')}) and (max-width: #{map-get($breakpoints, 'xl') - 1px})' + format-media-query-string($queries)} {
    @content;
  }
}

@mixin desktop($queries...) {
  @media #{'(min-width: #{map-get($breakpoints, 'xl')})'  + format-media-query-string($queries)} {
    @content;
  }
}

@mixin largeDesktop($queries...) {
  @media #{'(min-width: #{map-get($breakpoints, 'xxl')})'  + format-media-query-string($queries)} {
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if ($key == desktop) {
      @include desktop {
        @content;
      }
    } @else if ($key == tablet) {
      @include tablet {
        @content;
      }
    } @else if ($key == mobile) {
      @include mobile {
        @content;
      }
    } @else if ($key == largeDesktop) {
      @include largeDesktop {
        @content;
      }
    }
  }
}

@mixin accessibilityHidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

@mixin pattern($color) {
  background-image: linear-gradient(
                  -45deg,
                  $color,
                  $color 15%,
                  lighten($color, 35) 15%,
                  lighten($color, 35) 50%,
                  $color 50%,
                  $color 65%,
                  lighten($color, 35) 65%,
                  lighten($color, 35) 100%
  );
  background-size: 9px 9px;
}
