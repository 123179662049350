@import '../common/variables';
@import '../common/functions';

.default {
  color: get-color('text', 'default');
}

.contrast {
  color: get-color('text', 'contrast');
}

.gray040 {
  color: get-color('text', 'gray-040');
}

.gray030 {
  color: get-color('text', 'gray-030');
}

.red {
  color: get-color('text', 'red');
}

.accent {
  color: get-color('text', 'accent');
}

.lightColor {
  color: get-color('text', 'light');
}

.gray {
  color: get-color('text', 'gray');
}

.green {
  color: get-color('text', 'green');
}

.light {
  font-weight: map-get($weights, 'light');
}

.regular {
  font-weight: map-get($weights, 'regular');
}

.medium {
  font-weight: map-get($weights, 'medium');
}

.semibold {
  font-weight: map-get($weights, 'semibold');
}

.bold {
  font-weight: map-get($weights, 'bold');
}

.extraBold {
  font-weight: map-get($weights, 'extraBold');
}

.black {
  font-weight: map-get($weights, 'black');
}

.dmMono {
  font-family: 'DMMono', monospace;
}

.normal {
  font-style: normal;
}

.italic {
  font-style: italic;
}

.oblique {
  font-style: oblique;
}


.cerePlay {
  font-family: 'Yapari Variable Trial';
}