@import "src/styles/common/functions";
@import "src/styles/common/mixins";

.container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 24px 0;
}

@include media(tablet, mobile) {
  .container {
    border-top: 0;
    border-bottom: 0;
    padding: 0 20px;
  }
  .icon {
    transform: rotate(90deg);
    margin: 36px 0;
  }
}