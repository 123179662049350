@import "src/styles/common/mixins";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/base.styles";

body,
html {
  position: relative;
}

:root {
  --input-border: #8b8a8b;
  --input-focus-h: 245;
  --input-focus-s: 100%;
  --input-focus-l: 42%;
}

.main {
  background: #161D30 url("../src/assets/png/bg.png") no-repeat;
  background-position-x: right;
  overflow: hidden;
}

.button {
  color: #ffffff;
  padding: 8px 24px;
  border-radius: 30px;
  white-space: nowrap;
}

.filledButton {
  background: linear-gradient(79.06deg, #A733DD 0%, #EC69BF 100%);
  box-shadow: 0 0 80px rgba(125, 35, 214, 0.8);
}

.outlinedButton {
  border: 1px solid #e851c3;
}

.filledGreenButton {
  background: linear-gradient(90deg, #3DDB69 0%, #28BCDC 100%);
}

.video-container {
  position: relative;
  .videoBg {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display:inline-block;
    background: radial-gradient(100% 128.18% at 100% 50%, rgba(22, 29, 48, 0) 0%, rgba(22, 29, 48, 0.8) 77.29%);
  }
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  object-fit: fill;
}

.maskElement {
  border-radius: 8px;
}

@include media(desktop) {
  .video-container video {
    object-fit: cover;
  }
}

@include media(mobile) {
  .video-container video {
    object-fit: cover;
  }
  .maskElement {
    & > svg rect{
     clip-path: none!important;
      mask: none !important;
    }
  }
}