@import 'src/styles/common/functions';
.container {
  background: rgba(245, 250, 252, 0.07);
  border: 1px solid rgba(245, 250, 252, 0.15);
  backdrop-filter: blur(4px);

  border-radius: 16px;
}

.nftImage {
  border-radius: 16px 16px 0 0 ;
}

.info {
  padding: 16px 18px 20px;
  border-top: 1px solid rgba(245, 250, 252, 0.15);
  .usdc {
    path {
      fill: get-color('text', 'gray');
    }
  }
}

.connectWallet {
  width: 178px;
}

.share {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 28.8px;
  height: 28.8px;
  padding: 4.11px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(11px);
  border-radius: 13px;
}
