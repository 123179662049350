@import '../../../../../styles/common/mixins';
@import '../../../../../styles/typography/common';
@import '../../../../../styles/typography/title';

.title {
  margin: 0;
  font-weight: bold;

  &.title-h1 {
    @extend %title-36-48;
  }

  &.title-h2 {
    @extend %title-28-36;
  }

  &.title-h3 {
    @extend %title-20-24;
  }

  &.title-h4 {
    @extend %title-16-20;
  }

  &.title-h5 {
    @extend %title-14-16;
  }

  &.title-h6 {
    color: red;
  }
}
