@import "../../../../../styles/common/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .title {
    text-transform: uppercase;
  }
  .content {
    max-width: 470px;
    margin: 0 auto;
  }
  .learnMore {
    text-decoration: underline;
    font-size: 16px;
    line-height: 22px;
    margin-top: 18px;
  }
}
.customButton {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

@include media(mobile) {
  .buttonGroupSmall {
    button {
      width: 122px;
    }
  }
  .buttonGroup {
    button {
      width: 218px;
    }
  }
  .fluid {
    width: 192px!important;
  }
}