%text-10-16 {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.1em;
}

%text-12-16 {
  font-size: 12px;
  line-height: 16px;
}

%text-12-20 {
  font-size: 12px;
  line-height: 20px;
}

%text-13-16 {
  font-size: 13px;
  line-height: 16px;
}

%text-14-20 {
  font-size: 14px;
  line-height: 20px;
}

%text-16-20 {
  font-size: 16px;
  line-height: 20px;
}

%text-16-24 {
  font-size: 16px;
  line-height: 24px;
}

%text-18-24 {
  font-size: 18px;
  line-height: 24px;
}

%text-20-24 {
  font-size: 20px;
  line-height: 24px;
}

%text-20-28 {
  font-size: 20px;
  line-height: 28px;
}

%text-24-28 {
  font-size: 24px;
  line-height: 28.8px;
}

%text-24-29 {
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
}

%text-24-32 {
  font-size: 24px;
  line-height: 32px;
}

%text-36-45 {
  font-size: 36px;
  line-height: 45px;
}

%text-47-58 {
  font-size: 47px;
  line-height: 58px;
  text-transform: uppercase;
}

%text-60-64 {
  font-size: 60px;
  line-height: 64px;
  text-transform: uppercase;
}

%text-92-110 {
  font-size: 92px;
  line-height: 110px;
  text-transform: uppercase;
}

%text-104-126 {
  font-size: 104.7px;
  line-height: 126px;
  text-transform: uppercase;
}
