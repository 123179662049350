@import "src/styles/common/mixins";

.bg {
  position: absolute;
  bottom: 0;
  z-index: -1;
  height: 120%;
}

.bg1 {
  left: 0;
  transform: rotate(-180deg);
}
.bg2 {
  right: 0;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.iconBlock {
  align-self: center;
  width: 91.5px;
  height: 73px;
  background-image: url("../../../assets/svg/icons/backdrop-icon.svg");
  background-size: cover;
}
.buttonGroup {
  button {
    &:first-child {
      width: 182px;
    }
    &:last-child {
      width: 188px;
    }
  }
  a {
    width: 188px;
  }
}

@include media(mobile) {
  .title {
    text-transform: uppercase;
  }
  .description {
    max-width: 191px;
  }
}