@import './common/functions';
@import './typography/mixins';

@font-face {
  //font-weight: 600;
  font-family: 'Yapari Variable Trial';
  font-style: normal;
  src: url('../assets/fonts/Yapari-Trial/YapariTrial-SemiBold.woff2') format('woff2');
  //font-display: swap;
}

*,
::before,
::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

html {
  height: 100%;
}

svg {
  display: block;
}

#root {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
}

a {
  color: get-color('main', 'accent');
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

p {
  margin: 0;
}

.mq-notification {
  display: none;
}
