@import '../../../../../styles/typography/common';
@import '../../../../../styles/typography/text';

.text {
  margin: 0;

  &.text-10-16 {
    @extend %text-10-16;
  }

  &.text-13-16 {
    @extend %text-13-16;
  }

  &.text-12-16 {
    @extend %text-12-16;
  }

  &.text-12-20 {
    @extend %text-12-20;
  }

  &.text-14-20 {
    @extend %text-14-20;
  }

  &.text-16-20 {
    @extend %text-16-20;
  }

  &.text-16-24 {
    @extend %text-16-24;
  }

  &.text-18-24 {
    @extend %text-18-24;
  }

  &.text-20-24 {
    @extend %text-20-24;
  }

  &.text-20-28 {
    @extend %text-20-28;
  }
  &.text-24-28 {
    @extend %text-24-28
  }

  &.text-24-29 {
    @extend %text-24-29
  }

  &.text-24-32 {
    @extend %text-24-32;
  }

  &.text-36-45 {
    @extend %text-36-45;
  }

  &.text-47-58 {
    @extend %text-47-58;
  }

  &.text-60-64 {
    @extend %text-60-64;
  }

  &.text-92-110 {
    @extend %text-92-110;
  }

  &.text-104-126 {
    @extend %text-104-126;
  }
}
