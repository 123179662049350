@import "src/styles/common/mixins";

.bg {
  background: #161D30 url("../../../../src/assets/png/bg.png") no-repeat right;
}
.container{
  padding-top: 143px;
}
.game {
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  .picture {
    position: relative;
    z-index: 2;
    svg {
      z-index: -1;
    }
    img {
      height: 253px;
      width: 354px;
    }
  }
  .description {
    max-width: 390px;
  }
  .playGame {
    max-width: 260px;
  }
}

.gameSubstrate {
  position: absolute;
  top: 7px;
  left: 7px;
}

.gameTitle {
  text-transform: uppercase;
}

@include media(mobile) {
  .bg {
    background: none;
  }
  .container{
    padding: 64px 20px 0;
  }
  .game {
    &:nth-child(even) {
      flex-direction: column;
    }
  }
  .picture {
    svg {
      width: 100%;
    }

    img {
      width: 240.63px;
      height: auto;
    }
  }
}