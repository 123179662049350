.content {
  text-align: center;
}

.buttons {
  padding: 44px 64px 0;

  &.hasError {
    padding-top: 4px;
  }
}

.error {
  margin: 0;
}

.okButton {
  white-space: nowrap;
}
