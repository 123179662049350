@import "src/styles/common/mixins";
@import "src/styles/common/functions";

.container {
  width: 500px;
  height: 280px;
  margin: 0 auto;
}

.sliderBlock{
  padding-bottom: 64px;
  position: relative;
  z-index: 2;
}
.joinCommunity {
  width: 282px;
}

.connectWallet {
  border-color: get-color('text', 'gray');
}

@include media(tablet, mobile) {
  .connectWallet {
    border-color: get-color('text', 'accent');
  }
  .sliderBlock {
    padding: 0 20px;
  }
}
