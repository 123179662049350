@import 'src/styles/common/functions';
@import "src/styles/common/mixins";

.container {
  z-index: 1;
  position: relative;
  background: rgba(245, 250, 252, 0.03);
  border: 1px solid rgba(245, 250, 252, 0.15);
  backdrop-filter: blur(4px);

  border-radius: 16px;
  max-height: 480px;
  .mysteryBox {
    margin-top: 15%;
    width: 202.2px;
    height: 202.2px;
    filter: drop-shadow(0px 0px 64px #C98DFF);
    transform: rotate(15.33deg);
  }
  .mainTournament {
    padding: 0 0 38px 40px;
  }
}
.withPadding {
  padding: 20px 30px 20px 38px;
}
.tournamentContainer {
  z-index: 1;
}

.tournamentSubtitle {
  text-transform: uppercase;
}


.playGame {
  width: 170px;
  height: 56px;
}

.joinCommunity {
  width: 306px;
}

@include media(mobile) {
  .container {
    padding: 24px 0  24px 24px;
  }
  .title {
    text-transform: uppercase;
  }
  .tournament {
    padding: 0 20px;
  }

  .playGame {
    max-width: 170px;
  }

  .mysteryBox {
    display: flex;
    justify-self: flex-end;
    margin-top: -7%!important;
    margin-right: 15px!important;
    width: 185.93px!important;
    height: 184.35px!important;
  }
}
