@import "../../../styles/common/functions";
.container {
  overflow: hidden;
  background: get-color('background', 'accent');
  a {
    color: get-color('text', 'gray');
    background-color: transparent;
    padding: 0;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 28px;
  }
}
.line {
  z-index: 2;
  flex-shrink: 0;
  margin: 0;
  padding: 10px 15px;
  min-width: 100%;
  white-space: nowrap;
  text-transform: uppercase;
  animation-name: marqueeLine;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes marqueeLine {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}