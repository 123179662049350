@import "src/styles/common/mixins";

.description {
  max-width: 401px;
}

@include media(mobile) {
  .title {
    text-transform: uppercase;
    max-width: 350px;
  }
  .buttonGroup {
    button {
      max-width: 209px;
    }
  }
}