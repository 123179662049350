@import 'src/styles/common/functions';
@import "../../../styles/common/mixins";

.title {
  text-transform: uppercase;
}
.content {
  max-width: 470px;
  margin: 0 auto;
}

.submit {
  margin-top: 42px;
  width: 260px;
}

.button {
  width: 260px;
}

.form {
  width: 100%;
}

.input {
  font-size: 16px;
  line-height: 24px;
  font-family: inherit;
  padding: 16px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  transition: 180ms box-shadow ease-in-out;
  color: #FFFFFF;
}

//.input:focus {
//  border-color: hsl(var(--input-focus-h), var(--input-focus-s), var(--input-focus-l));
//  box-shadow: 0 0 0 3px
//    hsla(var(--input-focus-h), var(--input-focus-s), calc(var(--input-focus-l) + 40%), 0.8);
//  outline: 3px solid transparent;
//}

.input:not(textarea) {
  height: 56px;
}


.input[readonly] {
  border-style: dotted;
  cursor: not-allowed;
  color: #777;
}

.input[disabled] {
  --input-border: #ccc;

  background-color: #eee;
  cursor: not-allowed;
}

.label {
  display: block;
  color: get-color('text', 'gray');
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 4px;
}

.input + .label {
  margin-top: 2rem;
}

.form-control {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  display: grid;
  grid-template-columns: 18px auto;
  align-items: baseline;
  gap: 12px;
}

.form-control + .form-control {
  margin-top: 1em;
}

.form-control--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 18px;
  height: 18px;
  border: 2px solid #FFFFFF;
  border-radius: 2px;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: center;
  transition: 120ms transform ease-in-out;
  /* Windows High Contrast Mode */
  background-color: get-color('text', 'gray');
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.link {
  text-decoration: underline !important;
  margin-left: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
}

@include media(mobile) {
  .privacy {
    white-space: pre;
  }
}