%caption-10-16 {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.1em;
}

%caption-12-20 {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1em;
}

%caption-14-20 {
  font-size: 14px;
  line-height: 20px;
}

%caption-16-20 {
  font-size: 16px;
  line-height: 20px;
}

%caption-16-24 {
  font-size: 16px;
  line-height: 24px;
}

%caption-20-28 {
  font-size: 20px;
  line-height: 28px;
}

%caption-39-52 {
  font-size: 39px;
  line-height: 52px;
}
