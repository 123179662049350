@import '../common/variables';
@import '../common/functions';
@import 'text';
@import 'caption';
@import 'title';

@mixin caption($level, $weight: 'regular', $color: 'default', $transform: uppercase) {
  @extend %caption#{'-' + $level};

  color: get-color('text', $color);
  font-weight: map-get($weights, $weight);
  text-transform: $transform;
}

@mixin text($level, $weight: 'regular', $color: 'default', $transform: none) {
  @extend %text#{'-' + $level};

  color: get-color('text', $color);
  font-weight: map-get($weights, $weight);
  text-transform: $transform;
}

@mixin title($level, $weight: 'regular', $color: 'default') {
  @extend %title#{'-' + $level};

  color: get-color('text', $color);
  font-weight: map-get($weights, $weight);
}
