@import "src/styles/common/mixins";

.achievement {
  width: 408px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(75.5px);
  border-radius: 40px;
  padding: 24px 20px 36px 24px;
  h3 {
    max-width: 240px;
    font-weight: 600;
    font-size: 23px;
    line-height: 32px;
  }
}

.marketPlace {
  position: relative;
  padding-bottom: 96px;
  pointer-events: none;
}

.comingSoon {
  z-index: 2;
  position: absolute;
  top: 40%;
  left: 110px;
  text-align: center;

  transform: rotate(-6.07deg);
}

.address {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 8px 16px;
  background: rgba(233, 204, 255, 0.1);

  border-radius: 30px;
  height: 36px;
}

.coloredNumber {
  font-weight: 700;
  background: linear-gradient(79.06deg, #E851C3 0%, #F5A1D3 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.filters {
  .filter {
    height: 40px;
    padding: 8px 16px;
  }
}

.price {
  padding: 10px 27px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 24px;
  min-height: 48px;
}

@media (max-width: 1275px) {
  .achievementList {
    width: 100%;
    overflow-x: scroll;
    grid-template-columns: repeat(3, 408px);
  }
}

@include media(tablet) {
  .achievement {
    max-width: 260px;
    padding: 24px 20px 20px;
    h3 {
      font-size: 21px;
      line-height: 32px;
    }
    img {
      max-height: 200px;
    }
    .price {
      padding: 15px 27px;
    }
  }
}

@include media(tablet, mobile) {
  .marketPlace {
    padding-top: 27px;
    padding-right: 20px;
    padding-left: 20px;
    border: none;
    h2 {
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
    }
  }
  .comingSoon {
    transform: rotate(90deg);
  }
  .achievement {
    max-width: 260px;
    padding: 24px 20px 20px;
    h3 {
      font-size: 21px;
      line-height: 32px;
    }
    img {
      max-height: 200px;
    }
    .price {
      padding: 15px 27px;
    }
  }
  .achievementList {
    padding-left: 20px;
    padding-top: 0;
    overflow-x: scroll;
    grid-template-columns: repeat(3, 260px);
  }
  .comingSoon {
    left: 0;
    width: 100%;
    min-width: auto;
  }
}

@include media(tablet) {
  .achievementRow {
    flex-direction: column-reverse;
    row-gap: 10px;
    .address {
      width: fit-content;
    }
  }
  .marketPlace {
    h2 {
      padding: 0 20px;
    }
  }
  .filters {
    padding: 0 20px 0;
  }
}

@include media(mobile) {
  .achievement {
    h3 {
      font-size: 21px;
      line-height: 32px;
    }
  }
  .marketPlace {
    padding-bottom: 64px;
    h2 {
      padding: 0 20px;
    }
  }
  .comingSoon {
   white-space: nowrap;
  }
  .filters {
    padding: 0 20px 0;
    flex-direction: column;
    row-gap: 16px;
  }
  .achievementRow {
    flex-direction: column-reverse;
    row-gap: 10px;
    .address {
      width: fit-content;
    }
  }
}