@import '../../../../../styles/typography/common';
@import '../../../../../styles/typography/caption';

.caption {
  margin: 0;

  &.caption-10-16 {
    @extend %caption-10-16;
  }

  &.caption-12-20 {
    @extend %caption-12-20;
  }

  &.caption-14-20 {
    @extend %caption-14-20;
  }

  &.caption-16-20 {
    @extend %caption-16-20;
  }

  &.caption-16-24 {
    @extend %caption-16-24;
  }

  &.caption-20-28 {
    @extend %caption-20-28;
  }

  &.caption-39-52 {
    @extend %caption-39-52;
  }
}

.uppercase {
  text-transform: uppercase;
}
