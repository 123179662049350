@import '../../../styles/common/variables';
@import '../../../styles/common/mixins';
@import '../../../styles/typography/mixins';
@import './styles/functions';
@import './styles/mixins';

.button {
  white-space: nowrap;
  @include caption('14-20', 'semibold', 'default', none);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease, border 0.2s ease;
  appearance: none;
  user-select: none;

  &::-moz-focus-inner {
    border: 0;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
  }

  &:not(.loading).disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.icon {
  margin-left: 8px;
}

.withoutChildren {
  margin: 0;
}

a.button {
  text-decoration: none;
}

.primary {
  @include generate-skin-style('primary', 'base');

  &:hover:not(.disabled) {
    @include generate-skin-style('primary', 'hover');
  }

  &:active:not(.disabled) {
    @include generate-skin-style('primary', 'active');
  }
}

.outlined {
  @include generate-skin-style('outlined', 'base');

  &:hover:not(.disabled) {
    @include generate-skin-style('outlined', 'hover');
  }

  &:active:not(.disabled) {
    @include generate-skin-style('outlined', 'active');
  }
}

.secondary {
  @include generate-skin-style('secondary', 'base');
}

.shell {
  @include generate-skin-style('ghost', 'base');

  &:hover:not(.disabled) {
    @include generate-skin-style('ghost', 'hover');
  }

  &:active:not(.disabled) {
    @include generate-skin-style('ghost', 'active');
  }

  &.loading {
    line-height: inherit;
    background-color: inherit;
    border: none;
    box-shadow: inherit;
    user-select: none;
    pointer-events: none;
  }
}

.link {
  @extend .shell;

  color: get-color('text', 'accent');
  font-weight: 400;
  text-decoration: underline;
  line-height: normal;
  word-break: break-word;
}

.loading {
  color: transparent;
  background-image: url('../../../assets/svg/spinner.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px auto;
  user-select: none;
  pointer-events: none;

  &:hover,
  &:active {
    background-color: get-color('background', 'white');
  }

  > * {
    visibility: hidden;
  }
}

.small {
  padding: 7px 19px;
}

.medium {
  padding: 14px 24px;
}

.large {
  padding: 23px 19px;
}

.fluid {
  width: 100%;
}

@include media(mobile) {
  .outlined, .primary {
    &:hover:not(.disabled) {
      background-color: transparent;
    }
  }
  .primary {
    &:hover:not(.disabled) {
      background-color: get-color('background', 'accent');
    }
  }
}
