@import 'src/styles/common/functions';
@import "src/styles/common/mixins";

.leaderBoardContainer {
  padding: 22px 22px 22px 0;
}

.leaderboard {
  background: rgba(245, 250, 252, 0.07);
  border: 1px solid rgba(245, 250, 252, 0.15);
  border-radius: 8px;
  padding: 30px 24px 0;
  max-height: 375px;
  .tableHeader {
    height: 20px;
    overflow: inherit;
  }
  .row {
    overflow-y: scroll;
    p {
      &:first-child {
        display: flex;
        width: 110px;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 28px;

          background: rgba(245, 250, 252, 0.2);
          border-radius: 8px;
        }
      }
      &:nth-child(2) {
        display: flex;
        flex: 1;
      }
      &:last-child {
        display: flex;
        justify-content: center;
      }
    }
  }
  .leaderboardRow {
    background: rgba(245, 250, 252, 0.07);
    border-radius: 4px;
    padding: 8px 12px;
    & > p:first-child {
      width: 98px;
    }
  }
  .active {
    background: get-color('background', 'accent');
  }
}

.rank {
  align-self: center;
}

.header {
  overflow-y: inherit!important;
  p {
    text-transform: uppercase;
  }
}

@include media(mobile) {
  .leaderboard {
    margin-top: 8px;
    .tableHeader {
      height: 25px;
    }
    .row {
      p {
        &:first-child {
          width: 77px;
        }
      }
    }
    .leaderboardRow {
      & > p:first-child {
        width: 65px;
      }
    }
  }

  .leaderBoardContainer {
    padding: 22px 0 0 0;
  }
}