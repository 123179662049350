@import "src/styles/common/functions";

.link {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  color: #22194e;
  line-height: 0;

  svg {
    flex-shrink: 0;
  }

  & > span {
    margin-left: 14px;
  }

  &:hover {
    text-decoration: none;
  }
}

.whiteIcon {
  & > path {
    fill: get-color('background', 'gray');
  }
}
