@import './functions';

@mixin generate-skin-style($skin, $modifier) {
  @each $property, $value in get-skin-style($skin, $modifier) {
    #{$property}: $value;
  }

  svg {
    fill: get-skin-property-style($skin, $modifier, 'color');
  }
}
