@import "src/styles/common/mixins";

.footerBorderWrap {
  position: relative;
  border-top: 1px solid;

  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.11) 100%);
  border-image-slice: 100;
}

.link {
  color: #9A9A9A;
  font-size: 14px;
  line-height: 20px;
}

.getInTouch {
  text-decoration: none;
  pointer-events: none;
}

.social {
  display: grid;
  grid-template-columns: repeat(4, 20px);
  column-gap: 24px;
  align-items: center;
}

@include media(tablet, mobile) {
  .footerBorderWrap {
    padding: 0 20px;
  }
  .logo {
    margin-bottom: 36px;
  }
  .row {
    flex-direction: column;
    align-items: center;
  }
  .email {
    margin-bottom: 18px;
  }
  .col {
    padding-bottom: 35px;
  }
}

@include media(tablet) {
  .footer {
    padding-top: 80px;
  }
}

@include media(mobile) {
  .footerBorderWrap {
    border-bottom: 0;
  }
  .social {
    margin-bottom: 66px;
  }
  .footer {
    padding-top: 41px;
    text-align: center;
  }
  .col {
    grid-template-columns: 1fr;
    row-gap: 8px;
  }
  .cookieStatement {
    margin-bottom: 9px;
  }
}